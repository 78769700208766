<template>
  <nav class="relative flex items-center justify-between px-4 lg:px-20">
    <h2 class="flex items-center text-2xl uppercase font-bold text-cyma-purple">
      <RouterLink :to="{ name: 'index' }">
        <!-- CERTIFICADOS -->
        <!-- <Logo class="h-10 w-auto text-cyma-purple" /> -->
      </RouterLink>
    </h2>
    <ul class="flex">
    </ul>
  </nav>
</template>

<script setup lang="ts">
</script>
